/* eslint-disable eqeqeq */
/* eslint-disable no-bitwise */
import { useState } from 'react';
import { isEmbedded } from '../../utils/getEnvironment';
import useApiCall from "./useApiCall";
import useAppQuery from './useAppQuery';
import useAppPost from './useAppPost';
import useAppPatch from './useAppPatch';
import { toast } from 'react-toastify';

let loadingAccount = false;

function useAppAccount(isDevelopment, config) {
  const [account, setAccount] = useState(null);
  const [login, setLogin] = useState(null);
  const [logout, setLogout] = useState(null);
  const [register, setRegister] = useState(null);
  const [resetPassword, setResetPassword] = useState(null);
  const [verifyAccount, setVerifyAccount] = useState(null);
  const [changePassword, setChangePassword] = useState(null);
  const [patchImage, setPatchImage] = useState(null);
  const [updateAccount, setUpdateAccount] = useState(null);


  const API = useApiCall();
  const { setLoading, setReachedEnd, query, setQuery } = useAppQuery('auth', !isEmbedded ? [{ key: 'limit', value: 1 }] : null, []);
  const { setSaving, saveResults, setSaveResults } = useAppPost('auth');
  const { setPatching, patchResults, setPatchResults } = useAppPatch('auth');
  const { setPatching: setPatchingUser, patchResults: patchUserResults, setPatchResults: setPatchUserResults } = useAppPatch('users');
  if (login) {
    setLogin(null);
    setSaving(login);
  }
  if (logout) {
    setLogout(null);
    setPatching({ action: 'logout' });
  }
  if (register) {
    setRegister(null);
    setSaving({ action: 'createAccount', reply: 'Welcome', ...register });
  }
  if (resetPassword) {
    setResetPassword(null);
    setPatching({ action: 'resetPassword', ...resetPassword });
  }
  if (verifyAccount) {
    setVerifyAccount(null);
    setPatching({ action: 'verifyAccount', ...verifyAccount});
  }
  if (changePassword) {
    setChangePassword(null);
    setPatching({ ...changePassword });
  }
  if (updateAccount) {
    setUpdateAccount(null);
    setPatchingUser({ ...updateAccount });
  }
  if (patchImage) {
    setPatchImage(null);
    const image = { link: patchImage };
    setPatchingUser({ image });
  }
  if (patchUserResults || saveResults || patchResults) {
    if (saveResults && saveResults._id) {
      setTimeout(() => {
        toast.success('Account Registered - please check email for next steps!')
      }, 400);
    } else if (patchResults && patchResults.success) {
      setTimeout(() => {
        toast.success('Password Reset - please check email for next steps!')
      }, 400);
    }
    setPatchUserResults(null)
    setSaveResults(null);
    setPatchResults(null);
    setAccount(null);
    setReachedEnd(false);
    setQuery(null);
    setLoading(true);
  }

  if (!account && query) setAccount({ ...query });
  if (!isEmbedded || account) return { account, setAccount, setLogin, setLogout, setPatchImage, setRegister, setResetPassword, setVerifyAccount, setChangePassword, setUpdateAccount };

  const payload = { request: { action: "GetAccount" } };
  const userModel = config && config.models && config.models.find(({ name }) => name === 'users');

  function __loadAccount(account) {
    if (userModel && userModel.collection && userModel.collection._id) API(
      (response) => {
        loadingAccount = false;
        setAccount(response.body && response.body[0] || {});
      },
      "GET",
      `/v1/collections/${userModel.collection._id}/documents?email=${account.email}`
    );
    else {
      loadingAccount = false;
      setAccount(account || {});
    }
  }

  function __handleMessage(evt) {
    let data;
    try {
      data = JSON.parse(evt.data);
    } catch (e) {
      // UNCAUGHT ERROR
    }
    if (!data) return;

    if (data.request.action === "GetAccount") {
      // Save account to context
      const account = data.response.body;
      if (!account.email) {
        setAccount({});
      } else {
        __loadAccount(account);
      }

      // Remove event listener
      window.removeEventListener("message", __handleMessage, false);
    }
  }

  // (TO DO: Need both event attachers?)
  if (window.addEventListener) {
    window.addEventListener("message", __handleMessage, false);
  } else {
    window.attachEvent("onmessage", __handleMessage);
  }

  if (!loadingAccount) {
    loadingAccount = true;
    window.parent.postMessage(JSON.stringify(payload), "*");
  }

  return { account, setAccount, setLogin, setLogout, setPatchImage, setRegister, setResetPassword, setVerifyAccount, setChangePassword, setUpdateAccount };
}

export default useAppAccount;
