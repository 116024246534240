import React from "react";
import styled from "styled-components";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import CipshProclamation from "../../components/CipshProclamation";
import UnescoProclamation from "../../components/UnescoProclamation";
import YouTube from "../../components/YouTube";
import ImageFloatRight from "../../components/ImageFloatRight";
import { device } from "../../submodules/logictry_common/src/utils/device";

const Main = styled.main`
  > section {
    margin: 8rem auto;
    max-width: 960px;
    padding: 0 1rem;
  }
  #map {
    position: absolute;
    inset: 0;
  }
  
  h2 {
    margin-bottom: 2rem;
    font-size: 1.8rem;
    @media ${device.mobileL} {
      text-align: center;
    }
  }
`;

const Logictry = styled.section`
  img {
    width: 240px;
  }
  > div:nth-child(2) {
    float: right;
    margin-left: 2rem;
    margin-bottom: 2rem;
    text-align: center;
    display: flex;
    gap: 1rem;
    * {
      max-width: 100%;
    }
    @media ${device.mobileL} {
      float: unset;
      margin-left: unset;
      margin-bottom: unset;
    }
  }
`;
const Significance = styled.section`
  img {
    width: 240px;
  }
`;
const Creators = styled.section`
  img {
    width: 240px;
  }
  > div:last-child {
    margin: 2rem 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    gap: 2rem;
    > div {
      width: calc(50% - 1rem);
      @media ${device.mobileL} {
        width: 100%;
      }
    }
  }
`;

const About = () => {
  return (
    <Main>
      <Header>
        <h1>About</h1>
      </Header>
      <Logictry>
        <h2>Logictry Proclamation</h2>
        <div>
          <div>
            <img src="https://uploads-ssl.webflow.com/624c3a53733d821d4ba39d7a/646a3982d666dad4a2332e7c_chrisfronda-p-500.jpeg"></img>
            <div><b>Chris Fronda</b></div>
            <div>Chief Logic Officer</div>
          </div>
          <div>
            <img src="https://uploads-ssl.webflow.com/624c3a53733d821d4ba39d7a/646a39beb39363e2e398351b_chelseatoler-p-500.jpeg"></img>
            <div><b>Chelsea Toler</b></div>
            <div>Chief Impact Officer</div>
          </div>
        </div>
        <p>We define Logic as “the ability (wisdom) to decide what choice is best for you based upon your options.”</p>
        <p>In accordance with United Nations Sustainable Development Goals 16 & 17 and UNESCO's initiative, we believe that <b>collaboration</b> and <b>logic</b> prove imperative for helping create a better, more peaceful world.</p>
        <p>With the new era of AI, we are working tirelessly to humanize AI and to leverage our logic layer to help humans learn how to think rather than what to think. Alongside our partners, we can help close the decision gap for the world while democratizing access to some of the world’s most curated knowledge sources and leaders on a global scale. Logictry is proud to join other champions of Logic and to help inspire a <b>Logic Movement</b> focused on Ethical AI, advancement of UN SDGs 16 & 17, supporting the next generation of future leaders,  and World Peace with better access to education for all.</p>
        <blockquote>“We are honored to amplify the work of our shared partners championing the UN SDGs, Impact, Ethical AI, and Innovation to help change the world together. We see the Logic Movement as a key factor in achieving UN SDG 16 (Peace, Justice, and Strong Institutions) & UN SDG 17 (Partnerships for the Goals) by 2030. Collecting the wisdom of the world’s leading experts, academics, thought leaders, advocates, and philanthropists for the purpose of sharing their knowledge and logic with the world will enable millions of people to gain access to opportunities and a pathway to purpose like never before. With the onset of AI perhaps we are all inspired to dream a little bigger for what is possible for our futures. We hope you will dream boldly with us.” - Chelsea Toler, Chief Impact Officer of Logictry</blockquote>
        <p>Logictry is thrilled to partner alongside Impact for SDGs, the SDG Academy, NOVA Impact, The Cometa Collective, and the KFG Foundation, to continue our mission to scale the world’s wisdom to give everyone the power of choice. Together we host UN World Logic Day as a part of our hypothesis that knowledge sharing and education on Logical Intelligence (Artificial Intelligence + Human Intelligence = Logical Intelligence) will help bring people together and closer to world peace. Our vision is to provide global access to agency and opportunity for all. We see the AI era as a unique opportunity to help educate, empower, and activate around providing equal access to learning, leadership, and career growth in alignment with the UN SDGs through Logic.</p>
        <p><b>Call to Action:</b> As we lead up to UN World Logic Day 2024, we are encouraging our stakeholders to engage with us on ethical AI solutions to further the United Nations Sustainable Development Goals (SDGs).  Share your solutions with us at  #LOGICTRYAI and take our pledge to stand for ethical AI for the future.</p>
        <p><b>Logictry AI Pledge:</b> We are mobilizing a movement around Ethical AI and the SDGs. Take our Action Pledge to commit to one ethical AI solution through your foundation, corporate, network or stakeholders and share your solution here:  #LOGICTRYAI.</p>
        <p>We will highlight ideas, solutions, & stories at our upcoming global conference, UN World Logic Day 2024 make sure post your progress and actions on IG & LinkedIn using the #LOGICTRYAI for the chance to selected for the Austin or Global Showcase for UN World Logic Day 2024.</p>
        <p><a href="/about/logictry">Read more</a></p>
      </Logictry>
      <UnescoProclamation />
      <CipshProclamation />
      <Significance>
        <h2>January 14th Significance</h2>
        <ImageFloatRight>
          <img src="http://www.logica-universalis.org/im-wld/tarski-godel-january-14.jpg"></img>
          <div><b>Alfred Tarski / Kurt Gödel</b></div>
        </ImageFloatRight>
        <p>The logo of the Logic Day is connected with the one of the <a href="https://en.wikipedia.org/wiki/Esperanto_symbols" target="_blank">Esperanto</a>.</p>
        <p>The question of a universal language was popular among logicians at the turn of the 20th century. <a href="https://en.wikipedia.org/wiki/Giuseppe_Peano" target="_blank">Giuseppe Peano</a> created one, <a href="https://en.wikipedia.org/wiki/Latino_sine_flexione" target="_blank">Latino sine flexione</a>, <a href="https://en.wikipedia.org/wiki/Louis_Couturat" target="_blank">Louis Couturat</a>  another one,  <a href="https://en.wikipedia.org/wiki/Ido_language" target="_blank">Ido</a>, and <a href="https://en.wikipedia.org/wiki/Rudolf_Carnap" target="_blank">Rudolf Carnap</a> was fluently speaking Esperanto as explained in his  <a href="http://fitelson.org/confirmation/carnap_schilpp_volume.pdf" target="_blank">intellectual autobiography</a>. The idea of <a href="https://en.wikipedia.org/wiki/L._L._Zamenhof" target="_blank">Ludwik Lejzer Zamenhof</a>, creator of Esperanto, was to promote peace and understanding among human beings by the development of an artificial  universal language. This did not work. English is itself  being used as a universal language and it makes sense if we consider some of its typical features,  already pointed out at the end of the 19th century by <a href="https://en.wikipedia.org/wiki/Michel_Br%C3%A9al" target="_blank">Michel Bréal</a>,  who established <a href="https://www.tandfonline.com/doi/abs/10.1080/00437956.1948.11659331" target="_blank">"semantics"</a>, and who was the teacher of <a href="https://en.wikipedia.org/wiki/Ferdinand_de_Saussure" target="_blank">Ferdinand de Saussure</a>.</p>
        <p>But more important than a universal language is human reason which is itself by nature universal and that's  an excellent reason to promote logic. Nowadays few people know what logic is. And there are various reasons for that, in particular because it is spread in various departments and most of the time there are no interactions within a given university between people working in logic in a computer science department, a math department, a philosophy department, a linguistics department. And for the layman logic merely reduces to the adjective "logical", he has no idea that logic is a science.</p>
        <p>Logic is not  one field among others. If we take logic seriously, as the study of reasoning, and if  we consider that reasoning is the distinctive feature of human beings, considered as rational animals since the antiquity ("logical" animals originally in Greek), then logic is something really special. The World Logic Day is the celebration of humanity.</p>
      </Significance>
      <Creators>
        <h2>Founding of World Logic Day</h2>
        <ImageFloatRight>
          <img src="http://logica-universalis.org/im-wld-rio/jyb.jpg"></img>
          <div><b>Jean-Yves Beziau</b></div>
          <div>President of LUA & Professor at the University of Brazil</div>
        </ImageFloatRight>
        <p>Why creating a World Logic Day? Human beings are classically considered as "Logical Animals" (Latinized as "Rational Animals"). Logic, as reasoning, is a central feature of human beings. It is not very difficult to see what can be promoted on January 14, considered as the World Logic Day: rationality, understanding and intelligence.</p>
        <p>The beginning of the year is symbolically good: it is fine to start the new year with a logical perspective. According to the Julian calendar (calendar promoted by Julius Cæsar, in use from 45 B.C. to 1582 A.D., still in use in some locations), January 14 is indeed New Year’s Day. To unify all logicians in one day at the beginning of every year is a good way to boost logic.</p>
        <p>The basic idea of a World Logic Day and other related projects such as the Logic Prizes Contest, an Encyclopædia of Logic, Word Logic Conferences, is to put logic in its right place, i.e the first place.</p>
        <p>Jean-Yves Beziau (JYB) is a Swiss Logician, Philosopher and Mathematician, PhD in mathematics and PhD in Philosophy. He has been living and working in different places: France, Brazil, Poland, Corsica, California (UCLA, Stanford, UCSD), Switzerland. He is currently Professor at the University of Brazil in Rio de Janeiro, former Coordinator of Graduate Studies in Philosophy and former President of the Brazilian Academy of Philosophy.</p>
        <p>JYB is the creator of the <a href="https://www.youtube.com/watch?v=UJJDL9MKYOk" target="_blank" rel="nofollow"><em>World Logic Day</em></a>, yearly celebrated on January 14 (<a href="https://en.unesco.org/commemorations/international-days" target="_blank" rel="nofollow"><em>UNESCO international days</em></a>), the <a href="https://sites.google.com/view/unilog-2022/logic-prizes" target="_blank" rel="nofollow"><em>World Logic Prizes Contest</em></a>, the founder and Editor-in-Chief of the book series <a href="http://www.collegepublications.co.uk/lphd/" target="_blank" rel="nofollow"><em>Logic PhDs</em></a> and the logic area editor of the <a href="https://iep.utm.edu/" target="_blank" rel="nofollow"><em>Internet Encyclopedia of Philosophy</em></a>. </p>
        <p>JYB is the originator of Universal Logic as a general theory of logical structures, and the founder and Editor-in-Chief of the journal <a href="https://www.springer.com/journal/11787" target="_blank" rel="nofollow"><em>Logica Universalis</em></a> and book series <a href="http://logica-universalis.org/sul" target="_blank" rel="nofollow"><em>Studies in Universal Logic</em></a>, both published by Birkhäuser/Springer, Basel. He has recently launched the <a href="https://www.springer.com/journal/11787/updates/18988758" target="_blank" rel="nofollow"><em>Logica Universalis Webinar</em></a> and is the current president of <a href="http://logica-universalis.org/LUAD" target="_blank" rel="nofollow"><em>LUA</em></a> and
        is organizing a series of events on universal logic around the world: <a href="https://www.uni-log.org/" target="_blank" rel="nofollow"><em>UNILOG - World Congress and School on Universal Logic</em></a> (Montreux 2005, Xi'an 2007, Lisbon 2010, Rio de Janeiro, 2013, Istanbul 2015, Vichy 2018, Crete 2022).</p>
        <p>JYB has made important contributions in paraconsistent logic (former student of Newton da Costa, JYB is editor of the <a href="http://www.paraconsistency.org/" target="_blank" rel="nofollow"><em>Paraconsistent Newsletter</em></a> and organizer of the 3rd, 5th and 6th World Congress on Paraconsistency). JYB has shown in particular that the modal logic S5 is a paraconsistent logic and that the nameless corner of the square of opposition is a paraconsistent negation.
        JYB has revived the study of the square of opposition organizing interdisciplinary world events on this topic <a href="https://www.square-of-opposition.org/" target="_blank" rel="nofollow"><em>SQUARE</em></a> (Montreux 2007, Corsica 2010, Beirut 2012, Vatican 2014, Easter Island 2016, Crete 2018, Leuven 2022) and organizing the publication of special issues of journals and books on the subject.</p>
        <p>JYB is working at promoting logic in South America. He has launched in 2015 together with Marcelo Coniglio the
        <a href="http://www.sa-logic.org/" target="_blank" rel="nofollow"><em>South American Journal of Logic</em></a> and is organizing together with Luis Felipe Bartolo Alegre <a href="https://www.salome2024.org/" target="_blank" rel="nofollow"><em>SALOME 1 - the 1st South American LOgic MEeting</em></a> in Cusco, Peru, January 2024.</p>
        <p>JYB has also been working on imagination, symbolism and semiotics. He has systematically used images to write philosophical papers in a series of <a href="http://www.jyb-logic.org/imaginary" target="_blank" rel="nofollow"><em>IMAGINARY PAPERS</em></a> and organizing a big international event on imagination in Rio de Janeiro in 2018, <a href="http://www.imagination2018.fr/" target="_blank" rel="nofollow"><em>L'IMAGINATION</em></a>. As the President of the Brazilian Academy of Philosophy, JYB also has organized in the marvelous city the event <a href="https://sites.google.com/view/creativity2019/" target="_blank" rel="nofollow"><em>CREATIVITY'2019</em></a>. In Geneva, JYB organized the workshop <a href="http://www.jyb-logic.org/a" target="_blank" rel="nofollow"><em>ARBITRARINESS OF THE SIGN</em></a> part of Saussure CLG Centenary.</p>
        <p>With <a href="https://philpeople.org/profiles/ricardo-silvestre" target="_blank" rel="nofollow"><em>Ricardo Silvestre</em></a>, JYB has launched the series of events <a href="https://www.logicandreligion.com/3rd-world-congress" target="_blank" rel="nofollow"><em>WoCoLoR - World Congress on Logic and Religion</em></a> and created the association <a href="https://www.logicandreligion.com/lara" target="_blank" rel="nofollow"><em>LARA</em></a> which is organizing a <a href="https://www.logicandreligion.com/webinars" target="_blank" rel="nofollow"><em>webinar</em></a> on the topic.</p>
        <p>JYB is a member of the editorial board of various journals including
        <a href="https://www.mdpi.com/journal/philosophies" target="_blank" rel="nofollow"><em>Philosophies</em></a> (MPDI), <a href="https://www.mdpi.com/journal/logics" target="_blank" rel="nofollow"><em>Logics</em></a> (MPDI), <a href="https://www.frontiersin.org/journals/psychology" target="_blank" rel="nofollow"><em>Frontiers in Psychology</em></a> (Frontiers Media) and the <a href="https://academic.oup.com/logcom" target="_blank" rel="nofollow"><em>Journal of Logic and Computation</em></a> (Oxford University Press).</p>
        <p><a href="https://link.springer.com/article/10.1007/s11787-019-00221-5">1st World Logic Day: 14 January 2019</a></p>
        <div>
          <div>
            <YouTube url={'https://www.youtube.com/embed/UJJDL9MKYOk?si=AxsDfumWLZM2FV12'} />
          </div>
          <div>
            <YouTube url={'https://www.youtube.com/embed/OrdPQP2Og7k?si=mW5EJZ7q5dZsO1lH'} />
          </div>
          <div>
            <YouTube url={'https://www.youtube.com/embed/2ujhnjPcSZg?si=Ch071MGIMD1qctY-'} />
          </div>
          <div>
            <YouTube url={'https://www.youtube.com/embed/RayDgk-KyiQ?si=VmL-Erz-mhM1NVIY'} />
          </div>
        </div>
      </Creators>
      <Footer />
    </Main>
  )
};
export default About;
