import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ImageFloatRight from "../ImageFloatRight";

const CIPSH = styled.section`
  img {
    width: 480px;
  }
`;

const CipshProclamation = () => (
  <CIPSH>
    <h2>CIPSH Proclamation</h2>
    <ImageFloatRight>
      <img src="https://wld.cipsh.international/Banner.jpg"></img>
    </ImageFloatRight>
    <p>On 14 January 2019, the first World Logic Day was celebrated as an initiative of universities, research institutes, foundations and associations active in the fields of mathematics, philosophy, computer sciences, engineering, economics and cognitive sciences. The celebrations were organized around workshops, lectures and symposiums on the role of logic in human knowledge and society in about 60 cities all over the world, in Australia, Austria, Belgium, Brazil, Bulgaria, Canada, Czech Republic, Chile, China, Croatia, Denmark, France, Germany, Greece, Hungary, India, Iran, Italy, Kazakhstan, Mexico, Moldova, Poland, Portugal, Russia, Saudi Arabia, Spain, Tunisia, Turkey, Ukraine, United States of America, and the Vatican.</p>
    <p>Based on the success of this first World Logic Day, the UNESCO member Brazil proposed the proclamation of the World Logic Day to the UNESCO Executive Board. The proposal was discussed and adopted at the 207th session of the UNESCO Executive Board in October 2019.</p>
    <p>On 26 November 2019, the 40th General Conference of UNESCO proclaimed 14 January to be World Logic Day. The Conseil International de Philosophie et des Sciences Humaines (CIPSH) and its relevant member institutions were charged with coordinating this effort.</p>
    <p>On 14 January 2020, the second World Logic Day was celebrated under the auspices of UNESCO. The Director General of UNESCO, Audrey Azoulay, published a statement:</p>
    <p><a href="https://wld.cipsh.international/index.html">Read more</a></p>
  </CIPSH>
);
export default CipshProclamation;
