import React, { useState } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  > input {
    position: relative;
    width: 100%;
    border: none;
    outline: none;
    padding: 25px 10px 7.5px;
    border-radius: 4px;
    border: 1px solid;
    font-size: 16px;
  }
  > i {
    position: absolute;
    top: 0;
    left: 0;
    padding: 15px 10px;
    font-style: normal;
    transition: 0.5s;
    pointer-events: none;
  }
  input:focus ~ i, input:valid ~ i {
    transform: translateY(-7.5px);
    font-size: 0.8em;
  }
`;


const Input = ({ name, value, type, onChange, onBlur }: { name: string, value: string, type: string, onChange?: any, onBlur?: any } ) => {
  
  return (
    <Wrapper>
      <input
        required
        defaultValue={value}
        onChange={(e) => onChange && onChange(e.currentTarget.value)}
        onBlur={(e) => onBlur && onBlur(e.currentTarget.value)}
        type={type}
        name={name.toLowerCase()}
        autoComplete="on"
        autoCapitalize="off"
        autoCorrect="off"
      ></input> <i>{name}</i>
    </Wrapper>
  );
};
export default Input;
