import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Map from "../../components/Map";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import UnescoProclamation from "../../components/UnescoProclamation";
import CountDown from "../../components/Countdown";
import { device } from "../../submodules/logictry_common/src/utils/device";
import { NavLink } from "react-router-dom";
import Events from "../../components/Events";
import useAppQuery from "../../submodules/logictry_common/src/hooks/server/useAppQuery";
import useApiQuery from "../../submodules/logictry_common/src/hooks/server/useApiQuery";
import { EVENTS_COLLECTION } from "../../collections";

const Main = styled.main`
  > section {
    padding: 1rem;
    margin: 4rem auto;
    max-width: 960px;
  }
  #map {
    position: absolute;
    inset: 0;
  }
  h1, h2 {
    text-align: center;
    margin-bottom: 2rem;
  }
`;
const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  > div:first-child {
    width: 100%;
    height: 100vh;
    // background: url("https://www.unesco.org/sites/default/files/styles/banner_desktop/public/2023-01/world_logic_day_1760px.webp?h=cf01e707&itok=nbcW9liA");
    background-image: url("https://logictrystatic.s3.amazonaws.com/images/earth-1756274_1920.jpg");
    background-position: center;
    background-size: cover;
    filter: brightness(0.5);
  }
  > div:nth-child(2) {
    padding-top: 4rem;
    position: absolute;
    inset: 0;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h1 {
      font-size: 5rem;
    }
    h2 {
      font-size: 2.2rem;
    }
    @media ${device.tablet} {
      h1 {
        font-size: 3rem;
      }
      h2 {
        font-size: 1.5rem;
      }
    }
  }
`;
const Register = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  > * {
    text-decoration: none;
    > div {
      font-size: 1.2rem;
      margin: 2rem 0 0;
      padding: 0.75rem 2rem;
      text-align: center;
      border-radius: 2rem;
      display: block;
      border: 1px solid white;
      color: white;
      cursor:pointer;
      background-color: rgba(0,0,0,0.4);
    }
    > div:hover {
      background-color: white;
      color: black;
    }
  }
`;

const Home = () => {
  const { query: query2024 } = useApiQuery(EVENTS_COLLECTION, [{ key: 'sort', value: '+startTime' }, { key: 'limit', value: 200 }, { key: 'year', value: 2024 }], []);
  return (
    <Main>
      <Header>
        <div></div>
        <div>
          <h1>World Logic Day</h1>
          <h2>Join the global movement!</h2>
          <Register>
            <NavLink to={`/events/2024/austin`}>
              <div>World Logic Day - Austin TX</div>
            </NavLink>
          </Register>
        </div>
        <Navbar />
        <div style={{ position: 'absolute', bottom: '1rem', color: 'white', left: 0, right: 0, textAlign: 'center', fontSize: '0.7rem' }}>╲╱</div>
      </Header>
      <section style={{ width: '100%', maxWidth: '48rem' }}>
        <h2>January 14th, 2024 will mark the 6th year of the worldwide celebration of World Logic Day, created by LUA on January 14, 2019, recognized by UNESCO the same year and included in the UNESCO calendar of international days in 2020.</h2>
      </section>
      <UnescoProclamation />
      <section style={{ padding: 0 }}>
        <h2>2024 Events</h2>
        <div style={{ height: 550, position: 'relative', width: '100%', margin: 'auto'  }}>
          <Map events={query2024 || []} />
        </div>
      </section>
      <Events title="2024 Events" events={query2024 || []} />
      <Footer />
    </Main>
  )
};
export default Home;
