import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { device } from "../../submodules/logictry_common/src/utils/device";
import countdown from "../../utils/countdown";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 2rem 0.5rem;
    @media ${device.mobileL} {
      margin: 1rem 0.5rem;
    }
    > div:first-child {
      border-radius: 50%;
      height: 4rem;
      width: 4rem;
      text-align: center;
      line-height: 4rem;
      color: white;
      font-size: 2rem;
      @media ${device.mobileL} {
        font-size: 1.5rem;
        height: 2rem;
        width: 2rem;
        line-height: 2rem;
      }
    }
    > div:last-child {
      font-size: 0.7rem;
    }
  }
`;


const CountDown = (props) => {
  const [time, setTime] = useState(countdown(props.start ? new Date(props.startTime) : new Date(), new Date(props.endTime)));
  
  useEffect(() => {
    const interval = setInterval(() => {
      setTime(countdown(props.start ? new Date(props.startTime) : new Date(), new Date(props.endTime)));
    }, 1000);
    return () => clearInterval(interval);
  }, [time]);
  return (
    <Wrapper>
      <div>
      <div>{time.months}</div>
        <div>MONTHS</div>
      </div>
      <div>
        <div>{time.days}</div>
        <div>DAYS</div>
      </div>
      <div>
      <div>{time.hours}</div>
        <div>HOURS</div>
      </div>
      <div>
        <div>{time.minutes}</div>
        <div>MINUTES</div>
      </div>
      <div>
        <div>{time.seconds}</div>
        <div>SECONDS</div>
      </div>
    </Wrapper>
  )
}

export default CountDown;
