import React, { useState } from "react";
import styled from "styled-components";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { device } from "../../submodules/logictry_common/src/utils/device";
import ChangeableImage from "../../submodules/logictry_common/src/components/ChangeableImage";
import useConfig from "../../submodules/logictry_common/src/config/useConfig";
import Input from "../../components/Input";
const AppConstants = require('../../submodules/logictry_config/s3_config.json');

const Main = styled.main`
  > section:nth-child(2) {
    margin: 4rem auto;
    max-width: 600px;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    > div {
      margin: 0.5rem 0;
      text-align: center;
    }
  }
  h2 {
    margin: 2rem 0;
    font-size: 1.8rem;
    @media ${device.mobileL} {
      text-align: center;
    }
  }
`;
const CancelSave = styled.section`
  max-width: 800px;
  margin: 4rem auto;
  padding: 0 1rem;
  display: flex;
  justify-content: center;
  button {
    font-size: 1rem;
    margin: 1rem;
    padding: 0.5rem 2rem;
    text-align: center;
    border-radius: 2rem;
    display: block;
    border: 1px solid white;
    color: white;
    cursor:pointer;
    background-color: #f82249;
  }
`;


const Account = () => {
  const { account, setPatchImage, setLogout, setUpdateAccount } = useConfig();
  const [email, setEmail] = useState(account.email);
  const [fullname, setFullname ] = useState(account.fullname);
  const [username, setUsername ] = useState(account.username);
  const [description, setDescription ] = useState(account.description);
  const s3Path = `${account._id}/`;
  const signingUrl = `/v1${AppConstants.S3.signingUrls.userprofiles}`;
  // const s3Path = `${Company.company._id}/${UserAccount.account._id}/`;
  // const signingUrl = `/v1${AppConstants.S3.signingUrls.companyfiles}`;
  if (!account.loggedIn) window.location.href = window.location.origin;
  return (
    <Main>
      <Header whiteBackground />
      <section>
        <ChangeableImage
          allowDeleting
          onlyAllowImage
          s3Path={s3Path}
          signingUrl={signingUrl}
          style={{
            width: 180,
            height: 180,
            borderRadius: 90,
            border: '1px dashed'
          }}
          backgroundImage={account.image && account.image.link}
          onUploadFinish={(url: string) => {
            setPatchImage(url);
          }}
        />
        <button style={{ margin: '1rem 0' }} onClick={() => setLogout(true)}>Log Out</button>
        <h2>Account Settings</h2>
        <Input name="Fullname" type="text" value={fullname} onChange={setFullname} />
        <Input name="Username" type="text" value={username} onChange={setUsername} />
        <Input name="Bio" type="text" value={description} onChange={setDescription} />
        <Input name="Email" type="text" value={email} onChange={setEmail} />
      </section>
      <CancelSave>
        <button onClick={() => { window.location.reload(); }}>Reset</button>
        <button onClick={() => setUpdateAccount({ fullname, username, description, email })}>Save</button>
      </CancelSave>
      <Footer />
    </Main>
  )
};
export default Account;
