import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { device } from "../../submodules/logictry_common/src/utils/device";

const Wrapper = styled.div`
  float: right;
  margin-left: 2rem;
  margin-bottom: 2rem;
  text-align: center;
  max-width: 280px;
  * {
    max-width: 100%;
  }
  @media ${device.mobileL} {
    float: unset;
    margin-left: unset;
    margin-bottom: unset;
  }
`;

const ImageFloatRight = (props) => (
  <Wrapper>
    {props.children}
  </Wrapper>
);
export default ImageFloatRight;
