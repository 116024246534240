/**
 * Function that recursively merges keys together.
 * Example: Turns "deal[amount.subscription]" into "deal.amount.subscription".
 * 
 * Note: 'nestedKey' parameters can only be strings, with or without '.' period characters.
 */
const concatenateKeys = (object: any, ...nestedKeys: string[]) => {
  const splitKeys = nestedKeys.map(nestedKey => (nestedKey.split('.'))).reduce((a, c) => ([...a, ...c]))

  let v = object;
  for (const k of splitKeys) {
    if (!v) return;
    if (!Object.keys(v).includes(k)) {
      // If the key does not exist, return 'undefined'
      v = undefined;
      break;
    }
    v = v[k]
  }
  return v;
}

export default concatenateKeys;