import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ImageFloatRight from "../ImageFloatRight";

const UnescoProclamation = () => (
  <section>
    <h2 style={{ marginBottom: '2rem' }}>UNESCO Proclamation</h2>
    <ImageFloatRight>
      <img style={{ width: 240 }} src="https://uploads-ssl.webflow.com/624c3a53733d82bc64a39d5b/64849d5b6bff1a3a6f20a8f1_audrey.jpeg"></img>
      <div><b>Audrey Azoulay</b></div>
      <div>Director-General of UNESCO</div>
    </ImageFloatRight>
    <p>The ability to think is one of the most defining features of humankind. In different cultures, the definition of humanity is associated with concepts such as consciousness, knowledge and reason. According to the classic western tradition, human beings are defined as “rational” or “logical animals”. Logic, as the investigation on the principles of reasoning, has been studied by many civilizations throughout history and, since its earliest formulations, logic has played an important role in the development of philosophy and the sciences.</p>
    <p>Despite its undeniable relevance to the development of knowledge, sciences and technologies, there is little public awareness on the importance of logic. The proclamation of World Logic Day by UNESCO, in association with the International Council for Philosophy and Human Sciences (CIPSH), intends to bring the intellectual history, conceptual significance and practical implications of logic to the attention of interdisciplinary science communities and the broader public.</p>
    <blockquote>"At the dawn of this new decade – indeed, now more than ever – the discipline of logic is utterly vital to our societies and economies. Computer science and digital technology, which provide the structure for today’s ways of life, are rooted in logical and algorithmic reasoning."</blockquote>
    <p>A dynamic and global annual celebration of World Logic Day aims at fostering international cooperation, promoting the development of logic, in both research and teaching, supporting the activities of associations, universities and other institutions involved with logic, and enhancing public understanding of logic and its implications for science, technology and innovation. Furthermore, the celebration of World Logic Day can also contribute to the promotion of a culture of peace, dialogue and mutual understanding, based on the advancement of education and science.</p>
    <blockquote>"Artificial intelligence (AI), the unprecedented progress of which constitutes a technological and even anthropological revolution, is itself founded on logical reasoning. Through (...) the first global standard-setting instrument concerning the ethics of AI, UNESCO has undertaken to establish an ethical framework for this innovative product of logic."</blockquote>
    <p><a href="https://www.unesco.org/en/days/world-logic">Read more</a></p>
  </section>
);
export default UnescoProclamation;