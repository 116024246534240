import React, { useState } from "react";
import useConfig from "../../submodules/logictry_common/src/config/useConfig";
import Input from "../../components/Input";
import { NavLink } from "react-router-dom";
import Form from "../../components/Form";

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const { account, setResetPassword } = useConfig();
  if (account.loggedIn) window.location.href = window.location.origin;
  return (
    <Form>
      <h2>Reset Password</h2>
      <div>
        <Input name="Email" type="text" value={email} onChange={setEmail} />
        <div>
          <NavLink to="/login">Login</NavLink>
          <NavLink to="/register">Sign Up</NavLink>
        </div>
        <div>
          <button type="submit" value="Login" onClick={() => setResetPassword({ email })}>Reset Password</button>
        </div>
      </div>
    </Form>
  );
};
export default ResetPassword;
