/* eslint-disable react/no-danger */
/* eslint-disable global-require */
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import { Wrapper } from "@googlemaps/react-wrapper";

const MapWrapper = styled.div`
  position: absolute;
  inset: 0;
  border-radius: 0.5rem;
  overflow: hidden;
  #map {
    position: absolute;
    inset: 0;
  }
  #map__popup {
    padding: 0.5rem;
    max-width: 280px;
    img {
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      margin-bottom: 0.5rem;
      object-fit: cover;
    }
    h3 {
      font-size: 1rem;
      font-weight: 400;
    }
    div {
      font-size: 0.75rem;
    }
    a {
      text-decoration: none;
      > div:last-child {
        margin-top: 0.5rem;
        text-decoration: underline;
      }
    }
  }
`;



function MyMapComponent({
  center,
  zoom,
  events
}) {
  const ref = useRef();
  useEffect(() => {
    const map = new window.google.maps.Map(ref.current, {
      center: center || new window.google.maps.LatLng(20,-97.7511),
      zoom: zoom || 2,
    });
    events.forEach(({ _id, image, geo, url, externalUrl, year, name, startTime, location }) => {
      if (!geo || !geo.coordinates) return;
      const marker = new window.google.maps.Marker({
        position: { lng: geo.coordinates[0], lat: geo.coordinates[1] },
        map,
      });
      const infowindow = new window.google.maps.InfoWindow({
        content: `<div id="map__popup">
        <a href="${url && `/events/${year}/${url}` || `/events/${_id}`}">
          <img src="${image}"></img>
          <h3>${name}</h3>
          <div>${(new Date(startTime)).toLocaleString('default', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
          })}</div>
          <div>${location}</div>
          <div>View Event</div>
        </a>
      </div>`,
      });
      marker.addListener("click", () => {
        infowindow.open({
          anchor: marker,
          map,
        });
      });
    });
  });

  return <div ref={ref} id="map" />;
}

const OpenStreetMapBox = (props) => (
  <MapWrapper>
    <MyMapComponent events={props.events} />
  </MapWrapper>
);
OpenStreetMapBox.propTypes = {
  events: PropTypes.array,
};
export default OpenStreetMapBox;
