/*
This file is simply a middleware for conforming the common
library's 'useConfig' to the local App's AppConfig schema, and
then exporting so this 'useConfig' can be used throughout
the App.
*/

import { MyContext, useConfig as commonUseConfig } from "../contexts/ConfigContext";
import { AppConfig } from './AppConfig'

const useConfig = commonUseConfig as () => (Omit<MyContext, 'config'> & { config: AppConfig });

export default useConfig;