import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Map from "../../components/Map";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import EventsWidget from "../../components/Events";
import useApiQuery from "../../submodules/logictry_common/src/hooks/server/useApiQuery";
import { EVENTS_COLLECTION } from "../../collections";

const Main = styled.main`
  > section {
    margin: 8rem auto;
    max-width: 960px;
  }
  #map {
    position: absolute;
    inset: 0;
  }
  h2 {
    text-align: center;
    margin-bottom: 2rem;
  }
`;

const EventsWrapper = styled.section`
  > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    > a > div {
      text-align: center;
      > img {
        object-fit: cover;
        width: 400px;
        max-width: 100%;
      }
      h3 {
        margin-top: 1rem;
      }
    }
  }
`;
const JoinMovement = styled.section`
  width: 100%;
  > div:last-child {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 2rem;
    > div {
      flex: 1 0 0%;
      img {
        width: 100%;
        border-radius: 0.5rem;
      }
      h3 {
        margin-top: 0.5rem;
      }
    }
  }
`


const Events = () => {
  const { query: query2024 } = useApiQuery(EVENTS_COLLECTION, [{ key: 'sort', value: '+startTime' }, { key: 'limit', value: 200 }, { key: 'year', value: 2024 }], []);
  const { query: query2023 } = useApiQuery(EVENTS_COLLECTION, [{ key: 'sort', value: '+startTime' }, { key: 'limit', value: 200 }, { key: 'year', value: 2023 }], []);
  return (
    <Main>
      <Header>
        <h1>Events</h1>
      </Header>
      {/* <JoinMovement>
        <h2>Join the Logic Movement</h2>
        <div>
          <div>
            <img src="https://www.idm314.org/assets/img/idm-ph-03.jpg"></img>
            <h3>Organize an event</h3>
            <p>We'll help people in your town find out about your event.</p>
          </div>
          <div>
            <img src="https://www.idm314.org/assets/img/idm-ph-03.jpg"></img>
            <h3>View events</h3>
            <p>We'll help people in your town find out about your event.</p>
          </div>
          <div>
            <img src="https://www.idm314.org/assets/img/idm-ph-03.jpg"></img>
            <h3>Publish your event</h3>
            <p>We'll help people in your town find out about your event.</p>
          </div>
        </div>
      </JoinMovement> */}
      <section>
        <h2>2024 Events</h2>
        <div style={{ height: 500, position: 'relative', width: '100%', margin: 'auto'  }}>
          <Map events={query2024 || []} />
        </div>
      </section>
      <EventsWidget title="2024 Events" events={query2024 || []} />
      <EventsWidget title="2023 Events" events={query2023 || []} />
      <Footer />
    </Main>
  )
};
export default Events;
