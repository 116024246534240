import styled from "styled-components";

export default styled.button`
  font-size: 1rem;
  margin: 2rem auto 0;
  padding: 0.5rem 2rem;
  text-align: center;
  border-radius: 2rem;
  display: block;
  border: 1px solid white;
  color: white;
  cursor:pointer;
  background-color: #f82249;
`;