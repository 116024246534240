import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Map from "../../components/Map";
import Navbar from '../../components/Navbar/index';
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const Main = styled.main`
  > section {
    margin: 8rem auto;
    max-width: 960px;
  }
  #map {
    position: absolute;
    inset: 0;
  }
`;
const Banner = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  // img {
  //   width: 480px;
  // }
  > div:first-child {
    width: 100%;
    height: 24rem;
    background: url("https://www.unesco.org/sites/default/files/styles/banner_desktop/public/2023-01/world_logic_day_1760px.webp?h=cf01e707&itok=nbcW9liA");
    background-position: center;
    background-size: cover;
    filter: brightness(0.5);
  }
  > div:nth-child(2) {
    padding-top: 2rem;
    position: absolute;
    inset: 0;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h1 {
      font-size: 5rem;
    }
  }
`;


const EventYear = () => {
  return (
    <Main>
      <Header>Event Year</Header>
      <Footer />
    </Main>
  )
};
export default EventYear;
