import { useState, useEffect } from "react";
import { CollectionString } from "../../types/server/ApiTypes";
import useApiCall from "./useApiCall";

export default function useApiPatch(collection: CollectionString, id?: String, upsert?: Boolean) {
  const [updating, setUpdating] = useState<any>(null);
  const [updateResults, setUpdateResults] = useState<any>(null);
  const API = useApiCall();
  useEffect(() => {
    /* Send request */
    updating && (id || updating._id) &&
      API(
        (response) => {
          const { body } = response;
          setUpdating(null);
          setUpdateResults(body);
        },
        "PATCH",
        `/v1/collections/${collection}/documents/${(id || updating._id)}`,
        upsert && [{ key: 'upsert', value: 'true' }] || null,
        updating
      );
  }, [updating]);
  return { updating, setUpdating, updateResults, setUpdateResults };
}
