import React, { useState, useEffect } from "react";
import ReactDOM from 'react-dom';
import styled from "styled-components";
import { device } from "../../utils/device";


const Wrapper = styled.div`
  position: fixed;
  inset: 0;
  z-index: 1;
  background-color: #00000099;
  overflow: auto;
  padding: 1rem;
  > div:first-child {
    box-shadow: rgba(136, 152, 170, 0.3) 0px 0px 0px 1px, rgba(49, 49, 93, 0.3) 0px 15px 35px, rgba(0, 0, 0, 0.28) 0px 5px 15px;
    background-color: white;
    margin: 6rem auto 50vh;
    padding: 2rem;
    @media ${device.mobileL} {
      padding: 1rem;
    }
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    > * {
      margin: 0.75rem 0;
    }
    > input {
      width: 100%;
      padding: 0.5rem 1rem;
      border-radius: 0.5rem;
      display: inline-block;
      box-sizing: border-box;
      border-style: none;
      border: 1px solid black;
    }
    > select {
      width: 100%;
      padding: 0.5rem 1rem;
      margin: 8px 0;
      display: inline-block;
      border-radius: 0.5rem;
      box-sizing: border-box;
      border-style: none;
    }
    > input:focus, > input:active {
      outline-style: none;
    }
    button {
      padding: 0.5rem 2rem;
      color: white;
      border-radius: 0.5rem;
      border: 0;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    fieldset {
      border: 1px solid black;
      border-radius: 0.5rem;
      legend {
        font-size: 1.2rem;
      }
      > div {
        display: flex;
      align-items: center;
      }
      input {
        margin: 0.4rem;
        cursor: pointer;
      }
      label {
        flex: 1;
      }
    }
  }
  > div:last-child {
    position: absolute;
    top: 0rem;
    right: 0rem;
    font-size: 2rem;
    color: white;
    padding: 0.5rem 1rem;
    cursor: pointer;
  }
`;

const Popup = ({ maxWidth, children, onClose }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = '';
    }
  });
  return ReactDOM.createPortal((
    <Wrapper onClick={onClose}>
      <div style={{ maxWidth }} onClick={(e) => { e.stopPropagation(); }}>
        {children}
      </div>
      <div onClick={onClose}>x</div>
    </Wrapper>
  ), document.body)
}

export default Popup;
