import React, { useState } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 10px;
  border: 1px solid black;
  border-radius: 0.25rem;
  > select {
    width: 100%;
    padding: 0.5rem 0;
    display: inline-block;
    box-sizing: border-box;
    border-style: none;
    font-size: 16px;
  }
`;


const Select = ({ id, name, options, value, onChange }) => {
  return (
    <Wrapper>
      <select id={id} name={name} defaultValue={value} onChange={(e) => onChange(e.currentTarget.value)}>
        <option value="">- {name} -</option>
        {options.map((option) => <option value={option}>{option}</option>)}
      </select>
    </Wrapper>
  );
};

export default Select;
