import React, { useState } from "react";
import useConfig from "../../submodules/logictry_common/src/config/useConfig";
import Input from "../../components/Input";
import { NavLink } from "react-router-dom";
import Form from "../../components/Form";

let EMAIL = (new URLSearchParams(window.location.search)).get('email');
let TOKEN = (new URLSearchParams(window.location.search)).get('token');
const SET_PASSWORD = EMAIL && TOKEN;

const Register = () => {
  const [password, setPassword] = useState('');
  const [fullname, setFullname] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const { account, setRegister, setVerifyAccount, setChangePassword } = useConfig();
  if (account.loggedIn) window.location.href = window.location.origin;
  if (EMAIL && TOKEN) {
    console.log("SET")
    setVerifyAccount({ email: EMAIL, token: TOKEN });
  }
  EMAIL = '';
  TOKEN = '';
  if (SET_PASSWORD) {
    return (
      <Form>
        <h2>Set Password</h2>
        <div>
          <Input name="Password" type="password" value={password} onChange={setPassword} />
          <div>
            <NavLink to="/reset-password">Forgot Password</NavLink>
            <NavLink to="/login">Login</NavLink>
          </div>
          <div>
            <button type="submit" value="Login" onClick={() => setChangePassword({ password })}>Set Password</button>
          </div>
        </div>
      </Form>
    );
  }
  return (
    <Form>
      <h2>Register</h2>
      <div>
        <Input name="Fullname" type="text" value={fullname} onChange={setFullname} />
        <Input name="Username" type="text" value={username} onChange={setUsername} />
        <Input name="Email" type="text" value={email} onChange={setEmail} />
        <div>
          <NavLink to="/reset-password">Forgot Password</NavLink>
          <NavLink to="/login">Login</NavLink>
        </div>
        <div>
          <button type="submit" value="Login" onClick={() => setRegister({ fullname, username, email })}>Register</button>
        </div>
      </div>
    </Form>
  );
};
export default Register;
