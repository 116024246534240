import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Navbar from "../Navbar";
import { device } from "../../submodules/logictry_common/src/utils/device";
import { useLocation } from "react-router-dom";

const Wrapper = styled.header`
  width: 100%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;
  height: 24rem;
  @media ${device.mobileL} {
    height: 20rem;
  }
  > div:first-child {
    width: 100%;
    background-repeat: no-repeat;
    position: absolute;
    inset: 0;
    height: 100%;
    filter: brightness(0.5);
    background-position: center;
    background-size: cover;
  }
  > div:nth-child(2) {
    position: absolute;
    inset: 0;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 680px;
    margin: auto;
    h1 {
      font-size: 2.4rem;
    }
    @media ${device.mobileL} {
      h1 {
        font-size: 1.6rem;
      }
    }
  }
`;

const Header = (props) => {
  return (
    <Wrapper style={{
      height: props.whiteBackground ? '72px' : props.height,
      minHeight: props.minHeight
    }}>
      {props.whiteBackground && <div></div> || <div
        style={{
          backgroundImage: `url(${props.cover || 'https://logictrystatic.s3.amazonaws.com/images/earth-1756274_1920.jpg' || 'https://logictrystatic.s3.amazonaws.com/images/globe.jpg'})`,
        }}
      ></div>}
      <div>
        {props.children}
      </div>
      <Navbar whiteBackground={props.whiteBackground} />
    </Wrapper>
  )
}
export default Header;
