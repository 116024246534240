import React, { useState } from "react";
import useConfig from "../../submodules/logictry_common/src/config/useConfig";
import Input from "../../components/Input";
import { NavLink } from "react-router-dom";
import Form from "../../components/Form";



const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword ] = useState('');
  const { account, setLogin } = useConfig();
  if (account.loggedIn) window.location.href = window.location.origin;
  return (
    <Form>
      <h2>Sign In</h2>
      <div>
        <Input name="Email" type="text" value={email} onChange={setEmail} />
        <Input name="Password" type="password" value={password} onChange={setPassword} />
        <div>
          <NavLink to="/reset-password">Forgot Password</NavLink>
          <NavLink to="/register">Sign Up</NavLink>
        </div>
        <div>
          <button type="submit" value="Login" onClick={() => setLogin({ email, password })}>Login</button>
        </div>
      </div>
    </Form>
  );
};
export default Login;
