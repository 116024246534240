import React, { useState, useEffect } from "react";
import styled from "styled-components";
import useAppPost from "../../hooks/server/useAppPost";
import useApiPost from "../../hooks/server/useApiPost";
import { toast } from "react-toastify";
import Select from "../Select";
import Popup from "../Popup";

const Container = styled.div``;
const Button = styled.button`
  font-size: 1rem;
  margin: 2rem auto 0;
  padding: 0.5rem 2rem;
  text-align: center;
  border-radius: 2rem;
  display: block;
  border: 1px solid white;
  color: white;
  cursor:pointer;
`;
const Stripe = styled.div`
  form {
    background: white;
    width: 30vw;
    min-width: 500px;
    align-self: center;
    box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1), 0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
    border-radius: 7px;
    padding: 40px;
  }

  .hidden {
    display: none;
  }

  #payment-message {
    color: rgb(105, 115, 134);
    font-size: 16px;
    line-height: 20px;
    padding-top: 12px;
    text-align: center;
  }

  #payment-element {
    margin-bottom: 24px;
  }

  /* Buttons and links */
  button {
    background: #5469d4;
    font-family: Arial, sans-serif;
    color: #ffffff;
    border-radius: 4px;
    border: 0;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
  }
  button:hover {
    filter: contrast(115%);
  }
  button:disabled {
    opacity: 0.5;
    cursor: default;
  }

  /* spinner/processing state, errors */
  .spinner,
  .spinner:before,
  .spinner:after {
    border-radius: 50%;
  }
  .spinner {
    color: #ffffff;
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
  .spinner:before,
  .spinner:after {
    position: absolute;
    content: "";
  }
  .spinner:before {
    width: 10.4px;
    height: 20.4px;
    background: #5469d4;
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    -webkit-transform-origin: 10.4px 10.2px;
    transform-origin: 10.4px 10.2px;
    -webkit-animation: loading 2s infinite ease 1.5s;
    animation: loading 2s infinite ease 1.5s;
  }
  .spinner:after {
    width: 10.4px;
    height: 10.2px;
    background: #5469d4;
    border-radius: 0 10.2px 10.2px 0;
    top: -0.1px;
    left: 10.2px;
    -webkit-transform-origin: 0px 10.2px;
    transform-origin: 0px 10.2px;
    -webkit-animation: loading 2s infinite ease;
    animation: loading 2s infinite ease;
  }

  @-webkit-keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @media only screen and (max-width: 600px) {
    form {
      width: 80vw;
      min-width: initial;
    }
  }
`;
const getRadio = (id, placeholder, options, defaultIndex) => (
  <fieldset id={id}>
    <legend>{placeholder}</legend>
    {options.map((o, i) => (
      <div key={o}>
        <input type="radio" id={o} name={placeholder} value={o} defaultChecked={i === defaultIndex}></input>
        <label htmlFor={o}>{o}</label>
      </div>
    ))}
</fieldset>
);
const DiscountCode = styled.div`
  display: flex;
  align-items: center;
  > input {
    flex: 1;
  }
  > input {
    width: 100%;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    margin: 0 8px 0 0;
    display: inline-block;
    box-sizing: border-box;
    border-style: none;
    border: 1px solid black;
  }
`;

const TEST_PK = 'pk_test_51I0sBhIbCIn8aPGh9GserC3cjCRo9sNC0OI9LZpV70wbodIsU5COQLyqFPR84VNrjyp9bSmVDDMeY0qyVlqQcdlf00S6x7grTD';

let elements;
let stripe;
let tickets;
let paymentState;
const DISCOUNT_CODE = (new URLSearchParams(window.location.search)).get('discount');

const RegisterForm = ({ onClose, event, collections, buttonColor, name, email, ticket, metadata }) => {

  // All State variables
  const [discountCode, setDiscountCode] = useState(DISCOUNT_CODE);
  const [form, setForm] = useState(null);
  const [payment, setPayment] = useState(false);

  const { setSaving, saveResults, setSaveResults } = useAppPost('auth');
  const { setSaving: setSavingEmail } = useAppPost('emails');
  const { setSaving: setSavingContact } = useApiPost(collections.Contacts);
  const { saving: savingPayments, setSaving: setSavingPayments, saveResults: savingPaymentsResults } = useApiPost(collections.Payments);
  const { saving: savingPayment, setSaving: setSavingPayment, saveResults: saveResultsPayment, setSaveResults: setSaveResultsPayment} = useAppPost('payments');
  const { setSaving: setSavingAttendance, saveResults: saveResultsGuests, setSaveResults: setSaveResultsGuests } = useApiPost(collections.Guests);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => document.body.style.overflow = null;
  });

  // If registration succeeded
  if (saveResults) {
    if (saveResults._id) {
      const { tags, optIn } = form;
      setSaveResults(null);
      event.email && setSavingEmail({ to: getValue('register-email'), bcc: event.email.bcc, replyTo: event.email.replyTo, subject: event.email.subject, html: event.email.html });
      setSavingAttendance({ event: event._id, guest: saveResults._id, hide: optIn === 'No', tags });
    } else if (saveResults.error) {
      toast.error(`Invalid Email!`);
      setSaveResults(null);
    }
  }

  // If everything succeeded
  if (saveResultsGuests) {
    if (saveResultsGuests._id) {
      const { clientSecret, email, amount, status, ticket } = paymentState;
      toast.success(`RSVP Success!`);
      setSaveResultsGuests(null);
      setSavingEmail(null);
      setSavingAttendance(null);
      setForm(null);
      setSavingPayments({
        _id: saveResultsGuests._id,
        clientSecret,
        email,
        amount,
        status,
        ticket
      });
      setSavingContact({ _id: saveResultsGuests._id, name: getValue('register-name'), email: getValue('register-email') });
      onClose(true);
    } else if (saveResultsGuests.error) {
      setSaveResultsGuests(null);
      setSavingEmail(null);
      setSavingAttendance(null);
      setForm(null);
      toast.success(`Already registered!`);
      onClose(false);
    }
  }

  // Read discount codes
  const discount = discountCode && event.discountCodes && event.discountCodes[discountCode.toLowerCase()];
  tickets = event.tickets;
  stripe = stripe || event.stripe && window.Stripe(event.stripe);
  const discountAmount = discount ? ((100 - discount) / 100) : 1;

  const onLoad = (e) => {
    if (!e) return;
    const fullname = getValue('register-name');
    const email = getValue('register-email');
    const ticket = tickets && getFieldSetValue('register-ticket');
    const amount = getTicketAmount(tickets, ticket, discountAmount);
    if (saveResultsPayment && !savingPayments && !savingPaymentsResults) {
      const { clientSecret } = saveResultsPayment;
      paymentState = { ...paymentState, clientSecret };
      const appearance = {
        theme: 'stripe',
      };
      elements = stripe.elements({ appearance, clientSecret });
      const linkAuthenticationElement = elements.create("linkAuthentication");
      linkAuthenticationElement.mount("#link-authentication-element");
      const paymentElementOptions = {
        layout: "tabs",
        defaultValues: {
          billingDetails: {
            email
          }
        }
      };
      const paymentElement = elements.create("payment", paymentElementOptions);
      paymentElement.mount("#payment-element");
      return;
    }
    if (savingPayment || saveResultsPayment) return;
    document
      .querySelector("#payment-form")
      .addEventListener("submit", handleSubmit);
    setSavingPayment({
      amount,
      stripePK: event.stripe,
      metadata
    });
    async function handleSubmit(e) {
      e.preventDefault();
      setLoading(true);
      const { paymentIntent, error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: "",
          receipt_email: email,
        },
        redirect: 'if_required'
      });
      if (error && (error.type === "card_error" || error.type === "validation_error")) {
        showMessage(error.message);
        setLoading(false);
      } else if (paymentIntent && paymentIntent.status === 'succeeded') {
        const { amount, status } = paymentIntent;
        setPayment(false);
        setSavingPayment(null);
        setSaveResultsPayment(null);
        setSaving({ fullname, email, action: 'createAccount' });
        paymentState = { ...paymentState, amount, status };
      } else if (paymentIntent && paymentIntent.status === 'processing') {
        // Need to handle for bank account
      }
    }
    function showMessage(messageText) {
      const messageContainer = document.querySelector("#payment-message");
      messageContainer.classList.remove("hidden");
      messageContainer.textContent = messageText;
      setTimeout(function () {
        messageContainer.classList.add("hidden");
        messageText.textContent = "";
      }, 4000);
    }
    // Show a spinner on payment submission
    function setLoading(isLoading) {
      if (isLoading) {
        // Disable the button and show a spinner
        document.querySelector("#submit").disabled = true;
        document.querySelector("#spinner").classList.remove("hidden");
        document.querySelector("#button-text").classList.add("hidden");
      } else {
        document.querySelector("#submit").disabled = false;
        document.querySelector("#spinner").classList.add("hidden");
        document.querySelector("#button-text").classList.remove("hidden");
      }
    }
  }

  return (
    <Container>
      <Popup onClose={() => onClose(false)} maxWidth="480px">
        <h2>Register</h2>
        <input id="register-name" placeholder="Name" type="text" name="name" autoComplete="on" autoCapitalize="off" autoCorrect="off" defaultValue={name}></input>
        <input id="register-email" placeholder="Email" type="text" name="email" autoComplete="on" autoCapitalize="off" autoCorrect="off" defaultValue={email}></input>
        {event.tags && event.tags.map(({ name, choices, type }) => <Select id={`register-${name}`} name={name} options={choices} />)}
        {event.optIn && getRadio("register-opt-in", "Do you opt into our public guest list?", ['Yes', 'No'])}
        {event.discountCodes && <input id="register-discount" placeholder="Discount Code" type="text" name="discount-code" autoComplete="on" autoCapitalize="off" autoCorrect="off" defaultValue={discountCode} onChange={() => setDiscountCode((getValue('register-discount') || '').toLowerCase())}></input>}
        {tickets && getRadio("register-ticket", "Which ticket would you like?", tickets.map(({ description, price }) => `$${Math.round(price * discountAmount)} - ${description}`), tickets.findIndex(({ id }) => ticket === id))}
        <Button style={{ background: buttonColor }} onClick={() => {
          const optIn = getFieldSetValue('register-opt-in') || true;
          const tags = [];
          if (event.tags) event.tags.forEach(({ name }) => {
            const tagNode = document.getElementById(`register-${name}`);
            const tag = tagNode && tagNode.value;
            if (tag) tags.push({ key: name, value: [tag] });
          });
          const ticket = getFieldSetValue('register-ticket');
          const email = getValue('register-email');
          if (!getValue('register-name')) toast.error(`Name Missing!`);
          else if (!email) toast.error(`Email Missing!`);
          else if (tickets && !ticket) toast.error(`Ticket Missing!`);
          else {
            setForm({ optIn, tags });
            const amount = getTicketAmount(tickets, ticket, discountAmount);
            if (tickets && amount > 0) {
              paymentState = { email, ticket };
              setPayment(true);
            } else {
              paymentState = { email, ticket, status: 'succeeded' };
              setSaving({ fullname: getValue('register-name'), email, action: 'createAccount' });
            }
          }
        }}>RSVP</Button>
      </Popup>
      {payment && <Popup onClose={setPayment} maxWidth="480px">
        <Stripe style={{ border: 0, background: 'transparent' }} ref={(e) => onLoad(e)}>
          <form id="payment-form">
            <div id="link-authentication-element"></div>
            <div id="payment-element"></div>
            <button id="submit">
              <div className="spinner hidden" id="spinner"></div>
              <span id="button-text">Pay now</span>
            </button>
            <div id="payment-message" className="hidden"></div>
          </form>
        </Stripe>
      </Popup>}
    </Container>
  );
}
function getValue(id) {
  return (document.getElementById(id) || { value: '' }).value;
}
function getFieldSetValue(id) {
  try {
    const getInputChecked = Array.from((document.getElementById(id)).getElementsByTagName('input')).find((c) => c.checked);
    if (!getInputChecked) return null;
    return getInputChecked.value;
  } catch(_) {
    return null;
  }
}
function getTicketAmount(tickets, ticket, discountAmount) {
  return tickets && Math.round(tickets.find(({ description, price }) => `$${Math.round(price * discountAmount)} - ${description}` === ticket).price * discountAmount * 100);
}
export default RegisterForm;
