import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

const Wrapper = styled.footer`
  width: 100%;
  padding: 2rem;
  position: relative;
  background: #f1f4f6;
  display: flex;
  flex-direction: column;
  align-items: center;
  > div:first-child {
    width: 100%;
    text-align: center;
    padding: 1rem 0 0;
    font-size: 1.5rem;
  }
  > div:nth-child(2) {
    padding: 1rem 0 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    > * {
      color: black;
      padding: 1rem;
      font-size: 1rem;
      cursor: pointer;
      text-decoration: none;
    }
    *:hover, *:focus, *:active {
      color: black;
      text-decoration: underline;
    }
  }
  > div:nth-child(3) {
    padding: 0 0 1rem;
    text-align: center;
  }
`;

const Footer = () => (
  <Wrapper>
    <div>
      World Logic Day
    </div>
    <div>
      <NavLink to={'/'}>
        Home
      </NavLink>
      <NavLink to={'/about'}>
        About
      </NavLink>
      <NavLink to={'/events'}>
        Events
      </NavLink>
    </div>
    <div>
      © Copyright <a href="https://logictry.com">Logictry</a>. All Rights Reserved
    </div>
  </Wrapper>
)
export default Footer;