import { useState, useEffect } from "react";
import { QueryParam } from "../../components/Table";

import { apiCall, removeEventListeners } from "./logictryapi";

type UUID = string;

const generateUUID = (): UUID => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

type ApiResponse = any;

const useApiCall = () => {
  const [isActive, setIsActive] = useState(true);

  const [localUUIDs, setLocalUUIDs] = useState<UUID[]>([]);

  useEffect(
    () => () => {
      setIsActive(false);
      removeEventListeners(localUUIDs);
    },
    []
  );

  const handleApiCall = (
    cb: (response: ApiResponse) => void,
    method: "GET" | "POST" | "PATCH" | "DELETE",
    path: string,
    query?: QueryParam[] | null,
    body?: { [key: string]: any }
  ) => {
    const uuid = generateUUID();
    setLocalUUIDs((previous) => [...previous, uuid]);

    const executeCallback = (response: ApiResponse) => {
      // Remove event listener
      setLocalUUIDs((prev) => {
        return prev.filter((x) => x !== uuid);
      });
      removeEventListeners([uuid]);

      // Execute callback
      try {
        isActive && cb(response);
      } catch (e) {
        // UNCAUGHT ERROR
        console.log({ e });
      }
    };

    apiCall(uuid, executeCallback, method, path, query, body);
  };

  return handleApiCall;
};

export default useApiCall;
