import React, { useState } from "react";
import styled from "styled-components";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  > section {
    flex: 1;
    max-width: 100%;
    width: 400px;
    margin: 2rem 0;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  section > div {
    width: 100%;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
    border-radius: 8px;
    box-shadow: rgba(136, 152, 170, 0.1) 0px 0px 0px 1px, rgba(49, 49, 93, 0.1) 0px 15px 35px, rgba(0, 0, 0, 0.08) 0px 5px 15px;
    > div {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 40px;
      h2 {
        font-size: 2em;
        text-transform: uppercase;
      }
      > div {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 25px;
        button {
          position: relative;
          width: 100%;
          border: none;
          outline: none;
          padding: 16px 10px;
          border-radius: 4px;
          font-weight: 500;
          font-size: 1em;
          background: black;
          color: white;
        }
        > div:nth-last-child(2) {
          position: relative;
          width: 100%;
          display: flex;
          justify-content: space-between;
          > a {
            text-decoration: none;
          }
        }
      }
    }
  }
`;


const Form = ({ children }) => {
  return (
    <Main>
      <Header whiteBackground />
      <section>
        <div>
          <div>
            {children}
          </div>
        </div>
      </section>
      <Footer />
    </Main>
  );
};
export default Form;
