import React from "react";
import styled from "styled-components";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import CipshProclamation from "../../components/CipshProclamation";
import UnescoProclamation from "../../components/UnescoProclamation";
import YouTube from "../../components/YouTube";
import ImageFloatRight from "../../components/ImageFloatRight";
import { device } from "../../submodules/logictry_common/src/utils/device";

const Main = styled.main`
  > section {
    margin: 8rem auto;
    max-width: 960px;
    padding: 0 1rem;
  }
  #map {
    position: absolute;
    inset: 0;
  }
  
  h2 {
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: 1.3rem;
    @media ${device.mobileL} {
      text-align: center;
    }
  }
`;

const Logictry = styled.section`
  img {
    width: 240px;
  }
  > div:nth-child(2) {
    float: right;
    margin-left: 2rem;
    margin-bottom: 2rem;
    text-align: center;
    display: flex;
    gap: 1rem;
    * {
      max-width: 100%;
    }
    @media ${device.mobileL} {
      float: unset;
      margin-left: unset;
      margin-bottom: unset;
    }
  }
`;
const Significance = styled.section`
  img {
    width: 240px;
  }
`;
const Creators = styled.section`
  img {
    width: 240px;
  }
  > div:last-child {
    margin: 2rem 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    gap: 2rem;
    > div {
      width: calc(50% - 1rem);
      @media ${device.mobileL} {
        width: 100%;
      }
    }
  }
`;

const Organization = () => {
  return (
    <Main>
      <Header>
        <h1>Logictry Proclamation</h1>
      </Header>
      <Logictry>
        <h2>About Logictry's World Logic Day</h2>
        <div>
          <div>
            <img src="https://uploads-ssl.webflow.com/624c3a53733d821d4ba39d7a/646a3982d666dad4a2332e7c_chrisfronda-p-500.jpeg"></img>
            <div><b>Chris Fronda</b></div>
            <div>Chief Logic Officer</div>
          </div>
          <div>
            <img src="https://uploads-ssl.webflow.com/624c3a53733d821d4ba39d7a/646a39beb39363e2e398351b_chelseatoler-p-500.jpeg"></img>
            <div><b>Chelsea Toler</b></div>
            <div>Chief Impact Officer</div>
          </div>
        </div>
        <p>We define Logic as “the ability (wisdom) to decide what choice is best for you based upon your options.”</p>
        <p>In accordance with United Nations Sustainable Development Goals 16 & 17 and UNESCO's initiative, we believe that <b>collaboration</b> and <b>logic</b> prove imperative for helping create a better, more peaceful world.</p>
        <p>With the new era of AI, we are working tirelessly to humanize AI and to leverage our logic layer to help humans learn how to think rather than what to think. Alongside our partners, we can help close the decision gap for the world while democratizing access to some of the world’s most curated knowledge sources and leaders on a global scale. Logictry is proud to join other champions of Logic and to help inspire a <b>Logic Movement</b> focused on Ethical AI, advancement of UN SDGs 16 & 17, supporting the next generation of future leaders,  and World Peace with better access to education for all.</p>
        <blockquote>“We are honored to amplify the work of our shared partners championing the UN SDGs, Impact, Ethical AI, and Innovation to help change the world together. We see the Logic Movement as a key factor in achieving UN SDG 16 (Peace, Justice, and Strong Institutions) & UN SDG 17 (Partnerships for the Goals) by 2030. Collecting the wisdom of the world’s leading experts, academics, thought leaders, advocates, and philanthropists for the purpose of sharing their knowledge and logic with the world will enable millions of people to gain access to opportunities and a pathway to purpose like never before. With the onset of AI perhaps we are all inspired to dream a little bigger for what is possible for our futures. We hope you will dream boldly with us.” - Chelsea Toler, Chief Impact Officer of Logictry</blockquote>
        <h2>Logic as A Key Driver For Ethical AI, UN SDGs, The Next Generation & World Peace</h2>
        <p><a href="https://logictry.com">Logictry</a> is thrilled to partner alongside Impact for SDGs, the SDG Academy, NOVA Impact, The Cometa Collective, and the KFG Foundation, to continue our mission to scale the world’s wisdom to give everyone the power of choice. Together we host UN World Logic Day as a part of our hypothesis that knowledge sharing and education on Logical Intelligence (Artificial Intelligence + Human Intelligence = Logical Intelligence) will help bring people together and closer to world peace. Our vision is to provide global access to agency and opportunity for all. We see the AI era as a unique opportunity to help educate, empower, and activate around providing equal access to learning, leadership, and career growth in alignment with the UN SDGs through Logic.</p>
        <h2>Call to Action</h2> 
        <p>As we lead up to UN World Logic Day 2024, we are encouraging our stakeholders to engage with us on ethical AI solutions to further the United Nations Sustainable Development Goals (SDGs).  Share your solutions with us at  #LOGICTRYAI and take our pledge to stand for ethical AI for the future.</p>
        <h2>Logictry AI Pledge</h2> 
        <p>We are mobilizing a movement around Ethical AI and the SDGs. Take our Action Pledge to commit to one ethical AI solution through your foundation, corporate, network or stakeholders and share your solution here:  #LOGICTRYAI.</p>
        <p>We will highlight ideas, solutions, & stories at our upcoming global conference, UN World Logic Day 2024 make sure post your progress and actions on IG & LinkedIn using the #LOGICTRYAI for the chance to selected for the Austin or Global Showcase for UN World Logic Day 2024.</p>
        <h2>SDG Academy Look To Partner With Innovators In Business Like Logictry</h2>
        <p>In 2012, the UN Sustainable Development Solutions Network (SDSN) was launched under the auspices of the UN Secretary-General. It started with ten thematic working groups on a range of environmental, social, and economic topics, which contributed to the debate and ultimately the adoption of the Sustainable Development Goals (SDGs). The SDSN quickly grew and founded its global networks program; a membership-based alliance of top-tier knowledge-generating institutions. As of 2022, SDSN has members in 50 networks across 144 countries.</p>
        <p>In 2016, the SDSN expanded its mission with the creation of the SDG Academy - the premier source of high-quality resources and guidance on education for the SDGs, with the mandate to enrich the field of sustainable development and achieve Agenda 2030. The SDG Academy creates and curates relevant content on the SDGs, shares innovative pedagogies and training models, and nurtures a global community of learners to prepare this generation and the next to achieve sustainable development. </p>
        <p>The SDG Academy has long been invested in online education as a means to provide largescale access to quality learning opportunities and is excited to provide these resources in partnership with innovative businesses like Logictry to help train professionals on the SDGs.</p>
        <p>Together we are focused on the central goal of evolving our education systems so that the system created is resilient, inclusive, and capable of meeting the challenges faced by people and the planet.</p>
        <h2>A Few Thoughts From Logictry’s Co-Founders:</h2>
        <blockquote>“We are honored to amplify the work of our shared partners championing the UN SDGs, Impact, Ethical AI, and Innovation to help change the world together. We see the Logic Movement as a key factor in achieving UN SDG 16 (Peace, Justice, and Strong Institutions) & UN SDG 17 (Partnerships for the Goals) by 2030. Collecting the wisdom of the world’s leading experts, academics, thought leaders, advocates, and philanthropists for the purpose of sharing their knowledge and logic with the world will enable millions of people to gain access to opportunities and a pathway to purpose like never before. With the onset of AI perhaps we are all inspired to dream a little bigger for what is possible for our futures. We hope you will dream boldly with us.” - Chelsea Toler, Chief Impact Officer of Logictry</blockquote>
        <p>As we enter this next phase of growth in disruptive innovation, we look to showcase the integral aspects of safeguarding the “Logic Movement.” Logictry and our stakeholders, are well positioned to leverage our Logic layer to enable catalytic learning models focused on closing the decision gap to further knowledge transfer over models where individuals lack choice and agency.</p>
        <p>Additional support for the relevance of Logic amidst the new era of AI is shared by Director-General of UNESCO, Audrey Azoulay who stated “At the dawn of this new decade – indeed, now more than ever – the discipline of Logic is utterly vital to our societies and economies. Computer science and digital technology, which provide the structure for today’s ways of life, are rooted in logical and algorithmic reasoning.”</p>
        <p>Thought leaders like Sam Harris have also shared insights around how to help educate others on the value of Logic by modeling logical arguments and shared, “If someone doesn't value evidence, what evidence are you going to provide to prove that they should value it? If someone doesn’t value Logic, what logical argument could you provide to show the importance of Logic?” ― Sam Harris</p>
        <p>“Through making Logic accessible for all, we are making better Logic and decision-making possible for the world. Together we can champion sharing the wisdom of the privileged few to help create better access and opportunities for the many. Logictry recently launched <a href="https://logictry.com">Logictry</a>, an AI Powered Decision Assistant leveraging Logic, to scale the world’s wisdom giving everyone the power of choice. Embodying the lessons of Sam Harris, we believe now is the time for the innovators, technologists, and impact makers to launch ethical AI solutions with the intention of accelerating impact and a better, more peaceful, equal, and  world. In agreement with Leonard Nimoy ‘we believe Logic is the beginning of wisdom not the end.’”  - Chris Fronda, Co-Founder of Logictry </p>
        <h2>A Few Thoughts from UN World Logic Day Co-Chair:</h2>
        <p>“As AI continues to integrate across industries, there will be wider implications on governance from the administrative process to the enhancement of public-private collaborations (SDG 17) and how societies are governed. We are already seeing a divergent and nuanced approach to the term governance and what ethical AI presents as. Our policy considerations around ethical AI will also need to be informed by a universal definition in order to institutionalize the industry and lead on best practice. </p>
        <p>Artificial Intelligence (AI) has taken most industries into a new era of growth– it can be used as a catalytic tool for major corporations and wider industries to account for their environmental, social and impact footprints to disclose and propose new frameworks of metrics and diligence.  As this industry finds its feet, the regulatory environment will need to navigate around ethical AI considerations to safeguard, and account for, human rights protections.</p>
        <p>Closing the decision gap and the democratization of knowledge using “Logic” and tools like Logictry alongside vetted open source platforms further this thesis to deliver on the UN SDGs.”- <a href="https://nisaajetha.com/">Nisaa Jetha</a>, Founder of ImpactforSDGs & Global Co-Chair for UN World Logic Day 2024</p>
      </Logictry>
      <Footer />
    </Main>
  )
};
export default Organization;
