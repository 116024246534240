import { useState, useEffect } from "react";
import { CollectionString } from "../../types/server/ApiTypes";
import useApiCall from "./useApiCall";

export default function useApiPost(collection: CollectionString) {
  const [saving, setSaving] = useState<any>(null);
  const [saveResults, setSaveResults] = useState<any>(null);
  const API = useApiCall();
  useEffect(() => {
    /* Send request */
    saving &&
      API(
        (response) => {
          const { body } = response;
          setSaving(null);
          setSaveResults(body);
        },
        "POST",
        `/v1/collections/${collection}/documents`,
        null,
        saving
      );
  }, [saving]);
  return { saving, setSaving, saveResults, setSaveResults };
}
