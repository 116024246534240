import React, { useState } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  > textarea {
    position: relative;
    width: 100%;
    border: none;
    outline: none;
    padding: 25px 10px 7.5px;
    border-radius: 4px;
    border: 1px solid;
    font-size: 16px;
    min-height: 220px;
    resize: vertical;
  }
  > i {
    position: absolute;
    top: 0;
    left: 0;
    padding: 15px 10px;
    font-style: normal;
    transition: 0.5s;
    pointer-events: none;
  }
  textarea:focus ~ i, textarea:valid ~ i {
    transform: translateY(-7.5px);
    font-size: 0.8em;
  }
`;


const TextArea = ({ name, value, type, onChange }) => {
  
  return (
    <Wrapper>
      <textarea
        required
        defaultValue={value}
        onChange={(e) => onChange(e.currentTarget.value)}
        type={type} name={name.toLowerCase()}
        autoComplete="on"
        autoCapitalize="off"
        autoCorrect="off"
      ></textarea> <i>{name}</i>
    </Wrapper>
  );
};
export default TextArea;
