import { useState, useEffect } from "react";
import { QueryParam } from "../../components/Table";
import {
  CollectionString,
  ModelInstance,
  NestedObject,
} from "../../types/server/ApiTypes";
import useApiCall from "./useApiCall";
import concatenateKeys from '../../utils/concatenateKeys';

export default function useApiQuery(
  collection: CollectionString,
  queryParams?: QueryParam[],
  nestedObjects?: NestedObject[]
) {
  const path = `/v1/collections/${collection}/documents`;
  const API = useApiCall();
  const [query, setQuery] = useState<any>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [reachedEnd, setReachedEnd] = useState<boolean>(false);

  const dependencyString = JSON.stringify([
    collection,
    queryParams,
    nestedObjects,
  ]);

  if (queryParams) {
    // Add default 'limit' and 'offset' query parameters if they're not present
    if (!queryParams.find(({ key }) => key === "limit")) {
      queryParams.push({ key: "limit", value: 20 });
    }
    if (!queryParams.find(({ key }) => key === "offset")) {
      queryParams.push({ key: "offset", value: 0 });
    }
  }

  // (If request parameters change, reset the query)
  useEffect(() => {
    setReachedEnd(false);
    setLoading(true);
  }, [dependencyString]);

  useEffect(() => {
    const retrieveItems = () => {
      // (Check that we haven't already retrieved all available results)
      if (!reachedEnd && queryParams) {
        /* Make call to API */
        collection &&
          API(
            (response: any) => {
              const { body } = response;
              if (
                body.length !==
                queryParams.find(({ key }) => key === "limit")!.value
              ) {
                setReachedEnd(true);
              }
              if (nestedObjects) {
                let totalReturned = 0;
                const totalApiCalls = body.length * nestedObjects.length;
                const checkComplete = () => {
                  totalReturned += 1;
                  if (totalApiCalls !== totalReturned) return;
                  setQuery(body);
                  setLoading(false);
                };
                if (totalApiCalls > 0) {
                  const responses: any = {};
                  body.forEach((object: any) => {
                    nestedObjects.forEach(({ key, collection }) => {
                      const id = concatenateKeys(object, key);
                      if (!id || typeof id !== "string") {
                        checkComplete();
                        return;
                      }
                      const keys = key.split('.');
                      const nestedKeys = keys.slice(0, -1);
                      const lastKey = keys.slice(-1)[0];
                      const alreadyExistingResponse = responses[`/v1/collections/${collection}/documents/${id}`];
                      if (alreadyExistingResponse) {
                        if (nestedKeys.length > 0) concatenateKeys(object, nestedKeys.join(','))[lastKey] = alreadyExistingResponse;
                        else object[lastKey] = alreadyExistingResponse;
                        checkComplete();
                        return;
                      }
                      API(
                        (response: any) => {
                          const { body: nestedBody } = response;
                          responses[`/v1/collections/${collection}/documents/${id}`] = nestedBody;
                          if (nestedKeys.length > 0) concatenateKeys(object, nestedKeys.join(','))[lastKey] = nestedBody;
                          else object[lastKey] = nestedBody;
                          checkComplete();
                        },
                        "GET",
                        `/v1/collections/${collection}/documents/${id}`
                      );
                    });
                  });
                  return;
                }
              }
              setQuery(body);
              setLoading(false);
            },
            "GET",
            path,
            queryParams
          );
      } else {
        setLoading(false);
      }
    };
    retrieveItems();
  }, [loading]);

  return { loading, setLoading, reachedEnd, setReachedEnd, query, setQuery };
}
