import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

const Wrapper = styled.div`
  width: 100%;
  padding-bottom: 56%;
  position: relative;
  iframe {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }
`;

const YouTube = (props) => (
  <Wrapper>
    <iframe src={props.url} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen={true}></iframe>
  </Wrapper>
)
export default YouTube;
