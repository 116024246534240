import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import Button from "../../styledhtml/Button";

const EventsWrapper = styled.section`
  padding: 0 1rem;
  a {
    text-decoration: none;
  }
  > div {
    > a {
      text-decoration: none;
      > div {
        display: flex;
        width: 100%
        align-items: center;
        margin: 2rem 0;
        position: relative;
        border-radius: 0.5rem;
        overflow: hidden;
        max-width: 100%;
        > img {
          object-fit: cover;
          width: 3rem;
          height: 3rem;
          border-radius: 50%;
          margin-right: 1rem;
        }
        div {
          display: flex;
          justify-content: center;
          flex-direction: column;
          > div {
            font-size: 0.75rem;
          }
        }
      }
    }
  }
`;
function Events(props) {
  return (
    <EventsWrapper>
      <h2>{props.title}</h2>
      <div>
        {props.events.map(({ _id, name, startTime, country, image, location, year, url, externalUrl }) => (
          <a href={url && `/events/${year}/${url}` || `/events/${_id}`} key={`${name}${startTime}${country}`}>
            <div>
              <img alt={image} src={image} />
              <div>
                <h3>{name}</h3>
                <div>{(new Date(startTime)).toLocaleString()} | {country} | {location}</div>
              </div>
            </div>
          </a>
        ))}
      </div>
      <NavLink to={`/events/create`}>
        <Button>Add Event</Button>
      </NavLink>
    </EventsWrapper>
  )
}
export default Events;
